import { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import Layout from "../../components/layout/Layout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Link, useLocation } from "react-router-dom";
import {
  ActionContainer,
  AddUser,
  DataGridContainer,
  DataGridWrapper,
} from "./style";
import { ordersColumns } from "./ordersForm";
import { getOrders } from "../../api/ordersService";
import EventSelect from "../../components/custom/EventSelect";

type ListType = "User" | "Order" | "Order";

const OrdersPage = () => {


  const location = useLocation();
  const type: ListType = "Order"
  // const data = useData(type);
  const [data, setData] = useState<any[]>([]);
  const [eventId, setEventId] = useState('');

  const handleDelete = async (id: string) => {
    try {

    } catch (err) {
      console.log(err);
    }
  };
  const actions: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (params) => {
        return (
          <ActionContainer>
            <Link
              to={`/orders/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">View</button>
            </Link>
            {/* <button
              onClick={() => handleDelete(params.row.id)}
              className="btn deleteBtn"
            >
              Delete
            </button> */}
          </ActionContainer>
        );
      },
    },
  ];
  const getOrdersByEvent = (event_id: any) => {
    localStorage.setItem('orders_event_id', event_id);
    setEventId(event_id);
    getOrders(`event_id=${event_id}`).then(results => {
      setData(results);

    }).catch(err => {
      console.log(err.message);
    })
  }
  const exportAsExcel = async () => {
    // Define the columns for the Excel sheet
    const headers = [
      "Event",
      "Exhibitor",
      "Booth Number",
      "Product Code",
      "Item",
      "Category",
      "Quantity",
      "Item Price",
      "Total Price (net)",
      "VAT",
      "Total Price (Gross)"
    ];

    // Prepare data for each order and its products
    const excelData = [headers];

    data.forEach(order => {
      order.order_items.forEach((item: any) => {
        excelData.push([
          order.event.full_name, // Event name
          order.company?.name, // Exhibitor name company
          order.booth_no || "", // Booth Number 
          item.product.code, // Product Code
          item.product.name, // Item name
          item.product.category.name, // Category
          item.quantity, // Quantity
          parseFloat(item.price), // Item Price
          parseFloat(item.price) * item.quantity, // Total Price (net)
          parseFloat(item.price) * parseFloat(order.event.tax), // VAT
          parseFloat(item.price) * item.quantity * (1 + order.event.tax / 100) // Total Price (Gross)
        ]);
      });
    });

    // Create a worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ProductSheet");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob for the Excel file
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a link to download the file with a dynamic name
    const dateTime = new Date().toISOString().replace(/[:.-]/g, '_').split('T').join('_');
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Exhibitor_Product_Report_${dateTime}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  useEffect(() => {
    const fetchData = async () => {
      const res = await getOrders();
      if (res) {
        setData(res);
      }
    }
    fetchData();
  }, [])
  return (
    <Layout>
      <AddUser>
        <h1 className="title">Orders</h1>
        <EventSelect onSelect={getOrdersByEvent} selected={eventId} />
        {/* <Link
          to={`/orders/new`}
          state={{ type }}
          style={{ textDecoration: "none" }}
        >
          <button className="add">Add {type}</button>
        </Link> */}
        <button
          onClick={exportAsExcel}
          className="btn exportBtn"
        >
          Export
        </button>
      </AddUser>
      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "white" }}
            rows={data}
            columns={
              ordersColumns.concat(actions)
            }
            pageSize={10}
            rowsPerPageOptions={[10]}
          // checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Layout >
  );
};

export default OrdersPage;
