import { HTMLInputTypeAttribute } from "react";
import { GridColDef } from "@mui/x-data-grid";

export interface FormDataSource {
  id: string;
  label: string;
  type: HTMLInputTypeAttribute;
  placeholder: string;
  readOnly?: boolean;
}
export const productsColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "product",
    headerName: "Product",
    width: 250,
    renderCell: (params) => {
      return <span>{`${params.row.product.name}`}</span>;
    },
  },
  {
    field: "code",
    headerName: "Code",
    width: 250,
    renderCell: (params) => {
      return <span>{`${params.row.product.code || ''}`}</span>;
    },
  },

  // {
  //   field: "description",
  //   headerName: "Description",
  //   width: 350,
  // },
  // {
  //   field: "category",
  //   headerName: "Category",
  //   width: 200,
  //   renderCell: (params) => {
  //     return <span>{`${params.row.category.name}`}</span>;
  //   },
  // },
  {
    field: "price",
    headerName: "Price",
    width: 150,
    renderCell: (params) => {
      return <span>{`${params.row.price}`}</span>;
    },
  },
  {
    field: "stock",
    headerName: "Stock",
    width: 150,
    // renderCell: (params: GridValueGetterParams) => {
    //   return <span>{`${params.row.stock} pieces`}</span>;
    // },
  },
];

export const formProductInput: FormDataSource[] = [
  // {
  //   id: "product",
  //   label: "Product",
  //   type: "select",
  //   placeholder: "Product",
  // },
  // {
  //   id: "name",
  //   label: "Title",
  //   type: "text",
  //   placeholder: "Apple Macbook Pro",
  // },
  // {
  //   id: "description",
  //   label: "Description",
  //   type: "text",
  //   placeholder: "Description",
  // },
  // {
  //   id: "category",
  //   label: "Category",
  //   type: "select",
  //   placeholder: "Computers",
  // },
  {
    id: "price",
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: "stock",
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];